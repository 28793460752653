<template>
  <div
    v-if="dataset"
    class="emails-dataset-view page-padding py-7"
  >
    <div class="emails-dataset-view__header mb-4">
      <div
        style="max-width: 600px"
      >
        <BackLink
          :text="$t('emailsDatasets.back_to_datasets')"
          @click="() => $router.push({ name: 'EmailsDatasetsView' })"
          show-text
        />
        <HeaderName
          :editable="true"
          :item="dataset"
          :hint="`${$t('forms.name_hint')} ${$t('forms.unique_hint')}`"
          :validator="isNameValid"
          @save="(newName) => updateDataset({newName})"
          show-id
        />
        <ItemDescription
          :item="dataset"
          @save="(newDescription) => updateDataset({newDescription})"
        />
      </div>
      <div class="emails-dataset-view__actions">
        <v-text-field
          v-model="searchedEmailFileName"
          class="file-name-filter-field"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('emails.email_file_name_filter_placeholder')"
          @input="onFileNameFilterInput"
        />
        <div class="actions-buttons__container">
          <ButtonWithIcon
            class="add-emails-button"
            icon="fas fa-plus"
            color="primary"
            variant="outlined"
            :message="$t('emailsDatasets.add_emails')"
            :disabled="isDatasetUploading"
            @click="() => $refs.uploader.click()"
            rounded
          />
          <TableAction
            v-if="selectedEmails.length"
            prepend-icon="fas fa-trash"
            :label="$tc('emails.delete_email', selectedEmails.length)"
            @click="deleteSelectedEmails"
          />
          <TableAction
            v-if="selectedEmails.length"
            prepend-icon="fas fa-exchange-alt"
            :label="$tc('emails.move_email', selectedEmails.length)"
            @click="displayMoveEmailsDialog = !displayMoveEmailsDialog"
          />
        </div>
      </div>
    </div>
    <TableWithFooter
      :loading="loading || (isDatasetUploading && emails.length === 0)"
      :paginated-items-length="emails.length"
      :total="totalEmails"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
      @sort="onSort"
      sortable
    >
      <template #header>
        <v-col cols="auto">
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            hide-details
          />
        </v-col>
        <v-col cols="3">
          {{ $t('emails.file_name') }}
        </v-col>
        <v-col cols="4">
          {{ $t('emails.email_subject') }}
        </v-col>
        <v-col cols="2">
          {{ $t('emails.email_author') }}
        </v-col>
        <v-col cols="2">
          {{ $t('emails.email_recipients') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="email in emails"
            :key="email.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="email.selected"
                class="mt-0"
                hide-details
              />
            </v-col>
            <v-col cols="3">
              <ItemName
                :key="email.id"
                name-field="file_name"
                :item="email"
                @name-click="() => $router.push({ name: 'EmailView', params: { datasetId: dataset.id, emailId: email.id } })"
              />
            </v-col>
            <v-col cols="4">
              <v-tooltip
                bottom
              >
                <template #activator="{ props }">
                  <div
                    v-bind="props"
                    class="ellipsis"
                  >
                    {{ email.subject }}
                  </div>
                </template>
                {{ email.subject }}
              </v-tooltip>
            </v-col>
            <v-col cols="2">
              <v-tooltip
                bottom
              >
                <template #activator="{ props }">
                  <span v-bind="props">
                    {{ getEmailAuthor(email) }}
                  </span>
                </template>
                {{ email.author.smtp_address }}
              </v-tooltip>
            </v-col>
            <v-col
              class="emails-receiver-cell"
              cols="2"
            >
              {{ getEmailReceivers(email) }}
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <FileInput
      ref="uploader"
      :supported-extensions="$options.constants.SUPPORTED_EXTENSIONS"
      @change="handleUploadClick"
    />
    <MoveEmailsDialog
      v-if="displayMoveEmailsDialog"
      :ids-of-emails-to-move="selectedEmails.map((email) => email.id)"
      :source-dataset-id="datasetId"
      @close="displayMoveEmailsDialog = false"
      @confirm="onMoveEmailsConfirm"
    />
  </div>
</template>

<script>

import FileInput from '@/components/common/elements/Forms/FileInput.vue';
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter.vue';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI';
import { uploadEmails, SUPPORTED_EXTENSIONS } from './EmailUploadsHelper';
import HeaderName from '@/components/common/elements/General/HeaderName.vue';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import ItemDescription from '@/components/common/elements/General/ItemDescription.vue';
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon.vue';
import { getEmailAuthor, getEmailReceivers } from '@/utils/EmailsUtils';
import { displaySnackbarSuccess, displaySnackbarError } from '@/utils/snackbar.js';
import TableAction from '@/components/common/elements/Tables/TableAction.vue';
import { EmailsAPI } from '@/API/classify/EmailsAPI';
import MoveEmailsDialog from '@/components/extract/views/Studio/EmailsDatasets/MoveEmailsDialog.vue';
import { isNameValid } from '@/utils/FormValidation';


export default {
  name: 'EmailsDatasetView',

  components: {
    ItemName,
    TableWithFooter,
    FileInput,
    HeaderName,
    BackLink,
    ItemDescription,
    ButtonWithIcon,
    TableAction,
    MoveEmailsDialog,
  },

  constants: {
    FETCH_DATASET_TIMEOUT: 2000,
    SUPPORTED_EXTENSIONS,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      emails: [],
      totalEmails: 0,
      itemsPerPage,
      currentPage,
      dataset: null,
      loading: false,
      descendingSort: true,
      searchedEmailFileName: '',
      allSelected: false,
      displayMoveEmailsDialog: false,
    };
  },

  computed: {
    isDatasetUploading() {
      return this.dataset.status === 'uploading';
    },

    selectedEmails() {
      return this.emails.filter((email) => email.selected);
    }
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
      this.fetchEmails();
    },

    currentPage() {
      this.fetchEmails();
    },

    allSelected() {
      this.emails.forEach((email) => {
        email.selected = this.allSelected;
      });
    },
  },

  async created() {
    await this.fetchEmailsDataset();
    this.fetchEmails();
    this.initRecurrentDatasetFetching()
  },

  unmounted() {
    this.cancelRecurrentDatasetFetching();
  },

  methods: {
    getEmailAuthor,
    getEmailReceivers,
    isNameValid,
    async fetchEmailsDataset() {
      const response = await EmailsDatasetsAPI.get(this.datasetId);
      this.dataset = response.data;
    },

    async fetchEmails() {
      this.loading = true;
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const searchEmailFileName = this.searchedEmailFileName.trim().toLowerCase();
      const response = await EmailsDatasetsAPI.fetchEmails({
        datasetId: this.datasetId, offset, limit: this.itemsPerPage, descendingSort: this.descendingSort, fileName: searchEmailFileName});
      if (response) {
        this.emails = response.data;
        this.totalEmails = parseInt(response.headers['x-total-count'], 10);
      }
      this.loading = false;
    },

    resetCurrentPage() {
      this.currentPage = 1;
    },

    async handleUploadClick(files) {
      if (this.dataset.status === 'uploading') {
        displaySnackbarError(this.$t('emailsDatasets.dataset_in_uploading_state_error'));
        return;
      }
      try {
        await uploadEmails(this.dataset.id, files);
        await this.fetchEmailsDataset();
        this.initRecurrentDatasetFetching()
      } catch (error) {
        displaySnackbarError(this.$t('emailsDatasets.emails_upload_error', { detail: error.response.data.detail }));
      }
    },

    /* If a dataset is in an uploading status we need to recurrently
     * fetch this dataset until it have a different status than uploading. */
    initRecurrentDatasetFetching() {
      if (this.dataset.status === 'uploading') {
        this.recurrentDataseFetchingId = setInterval(async () => {
          await this.fetchEmailsDataset();
          if (this.dataset.status !== 'uploading') {
            this.cancelRecurrentDatasetFetching()
            await this.fetchEmails();
            displaySnackbarSuccess(this.$t('emailsDatasets.emails_upload_success'));
          }
        }, this.$options.constants.FETCH_DATASET_TIMEOUT);
      }
    },

    cancelRecurrentDatasetFetching() {
      clearInterval(this.recurrentDataseFetchingId);
      this.recurrentDataseFetchingId = null
    },

    onSort(descendingSort) {
      this.descendingSort = descendingSort;
      this.fetchEmails();
    },

    async updateDataset({newName = null, newDescription = null}) {
      try {
        const response = await EmailsDatasetsAPI.update({datasetId: this.datasetId, name: newName, description: newDescription });
        this.dataset = response.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async onFileNameFilterInput() {
      this.resetCurrentPage();
      await this.fetchEmails();
    },

    async deleteSelectedEmails() {
      const emailsIds = this.selectedEmails.map((email) => email.id);
      await EmailsAPI.deleteEmails({emailsIds});
      this.allSelected = false;
      this.fetchEmails();
    },

    onMoveEmailsConfirm() {
      this.displayMoveEmailsDialog = false;
      displaySnackbarSuccess(this.$tc('emailsDatasets.emails_moved_success', this.selectedEmails.length));
      this.fetchEmails();
    },
  },

  props: {
    datasetId: {
      type: Number,
      required: true,
    },
  },

  emits: [],
}
</script>

<style lang="scss" scoped>

.emails-dataset-view__header {
  display: flex;
  gap: 30px;
  flex-direction: column;

  .emails-dataset-view__actions {
    display: flex;
    gap: 20px;

    .file-name-filter-field {
      max-width: 300px;
    }

    .actions-buttons__container {
      max-height: 42px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.emails-receiver-cell {
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
}

</style>
