<template>
  <div class="top-gap">
    <div class="filter-container bottom-gap d-flex align-middle">
      <div class="d-flex align-center">
        <label class="label">
          {{ $t('workflows.jobs.filter_by_action') }}
        </label>
        <WorkflowActionSelect
          class="ml-2 mb-0"
          :selected="selectedAction && selectedAction.name || ''"
          @selected-changed="(action) => selectedAction = action"
        />
      </div>
      <div class="d-flex align-center ml-8">
        <label class="label">
          {{ $t('workflows.jobs.filter_by_status') }}
        </label>
        <v-chip
          v-for="state in jobStates"
          :key="state"
          class="ml-2"
          style="border: 1px rgb(var(--v-theme-primary)) solid !important;"
          color="primary"
          :variant="statusFilters.includes(state) && 'flat' || 'outlined'"
          @click="handleStatusFilter(state)"
        >
          {{ $t(`workflows.test.${state}`) }}
        </v-chip>
      </div>
    </div>

    <TableWithFooter
      no-results-message="workflows.no_results"
      :loading="loading"
      :paginated-items-length="paginatedJobs.length"
      :total="totalJobs"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="4">
          {{ $t('workflows.name') }}
        </v-col>
        <v-col cols="1">
          {{ $t('workflows.jobs.id') }}
        </v-col>
        <v-col cols="2">
          {{ $t('workflows.created_at') }}
        </v-col>
        <v-col cols="2">
          {{ $t('workflows.steps.name') }}
        </v-col>
        <v-col cols="1">
          {{ $t('workflows.jobs.is_test') }}
        </v-col>
        <v-col cols="2">
          {{ $t('workflows.steps.state') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedJobs"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="4">
              <ItemName
                :key="item.workflow_id"
                :item="item.workflow"
                :clickable="true"
                @name-click="navigateToWorkflow(item.workflow_id)"
              />
            </v-col>
            <v-col cols="1">
              <ItemName
                :key="item.id"
                :item="item"
                :clickable="true"
                :show-id="false"
                @name-click="navigateToJob(item.id)"
              />
            </v-col>
            <v-col cols="2">
              {{ formatDate(item.updated_at) }}
            </v-col>
            <v-col cols="2">
              {{ item.step_name }}
            </v-col>
            <v-col cols="1">
              {{ item.is_test ? $t('forms.yes') : $t('forms.no') }}
            </v-col>
            <v-col cols="2">
              <v-chip
                class="noselect"
                style="margin-top: -5px;"
                color="primary"
              >
                {{ $t(`workflows.test.${item.state}`) }}
              </v-chip>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
  </div>
  <DeleteDialog
    v-model="deleteDialog"
    :title="$t('workflows.delete')"
    :message="$t('workflows.delete_confirmation')"
    @confirm="deleteJobs"
    @close="deleteDialog = false"
  />
</template>

<script>
import _ from 'lodash';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import format_mixin from '@/mixins/format.js';
import ItemName from '@/components/common/elements/General/ItemName';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import WorkflowActionSelect from'@/components/extract/views/Workflows/WorkflowActionSelect';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';

export default {
  name: 'JobsTable',

  mixins: [format_mixin],

  components: {
    ItemName,
    DeleteDialog,
    TableWithFooter,
    WorkflowActionSelect,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`
    );

    return {
      allSelected: false,
      deleteDialog: false,
      totalJobs: 0,
      paginatedWorkflows: [],
      paginatedJobs: [],
      loading: false,
      id: -1,
      steps: [],
      transitions: [],
      jobHistory: [],
      jobStates: ['started', 'error', 'done'],
      statusFilters: [],
      actions: [],
      selectedAction: null,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    selected() {
      if (this.paginatedJobs.length > 0){
        return this.paginatedJobs.filter(item => item.selected);
      }
      return [];
    },

    stepFilter() {
      return this.selectedAction && this.selectedAction.value && this.selectedAction.value.action || '';
    },
  },

  watch: {
    selectedAction(){
      this.getJobs();
    },

    statusFilters() {
      this.getJobs();
    },

    trimmedFilter: _.debounce(
      function() {
        this.getJobs();
      }, 300
    ),

    itemsPerPage(){
      this.getJobs();
    },

    currentPage(){
      this.getJobs();
    }
  },

  async mounted() {
    this.loading = true;
    await this.getJobs()
  },

  methods: {
    resetCurrentPage(){
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedJobs.forEach(item => {
        item.selected = false;
      });
    },

    async getJobs(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage,
    ) {
      try {
        const options = {
          stepFilter: this.stepFilter,
          statusFilters: this.statusFilters.join(','),
          uuid: '',
          limit,
          offset,
          sortDesc: true
        }
        const response = await WorkflowAPI.getJobs(null, options);
        const { data, headers } = response;
        this.paginatedJobs = data.map(job => {
          return {
            ...job,
            name: job.id,
            workflow: {
              id: job.workflow_id,
              name: job.workflow_name
            }
          };
        });

        this.totalJobs = parseInt(headers['x-total-count'], 10);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async deleteJobs(){
      await Promise.all(this.selected.map(async job => {
        if (job.state === 'done'){
          try{
            await WorkflowAPI.deleteJob(job.id);
          } catch (error){
            console.log(error);
          }
        }
      }));
      this.finishDeletion();
    },
    
    async finishDeletion(){
      const { currentPage, itemsPerPage} = this;
      await this.getJobs();
      const lastPage = Math.max(1, Math.ceil(this.totalJobs / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('workflows.delete_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    navigateToWorkflow(id) {
      this.$router.push(
        {
          name: 'ConfigureWorkflow',
          params: {
            id: id,
        }
      });
    },

    navigateToJob(id) {
      this.$router.push(
        {
          name: 'JobHistory',
          params: {
            id: id,
        }
      });
    },

    handleStatusFilter(state){
      if (!this.statusFilters.includes(state)) {
        this.statusFilters = [...this.statusFilters, state];
      } else {
        this.statusFilters = this.statusFilters.filter(s => s !== state);
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.hover-highlight {
  &:hover {
    border: 2px solid #502BFF !important;
  }
}
</style>
