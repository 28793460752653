<template>
  <div class="recycle-bin-view page-padding py-7">
    <PageTabs
      v-if="config && tabItems.length > 0 && activeTab != ''"
      v-model="activeTab"
      :tabs="tabItems"
    />
    <DeletedDocTypes
      v-if="config && tabItems.length > 0 && activeTab === 'doctypes'"
      :config="config"
      @change-total="total => docTypesTotal = total"
    />
    <DeletedDatasets
      v-if="config && tabItems.length > 0 && activeTab === 'datasets'"
      :config="config"
      @change-total="total => datasetsTotal = total"
    />
    <v-card
      v-else-if="config && activeTab === ''"
      class="empty-bin-card vertical-centered"
    >
      <h3 class="bottom-gap text-h3">
        <RecycleBinIcon
          class="right-gap-sm inline-middle"
          style="margin-top: -4px"
          color="primary"
        />
        {{ $t('recycleBin.empty') }}
      </h3>
      {{ $t('recycleBin.empty_message', {number: config.delete_retention_days}) }}
    </v-card>
  </div>
</template>

<script>
import PageTabs from '@/components/common/elements/General/PageTabs';
import DeletedDocTypes from '@/components/extract/views/RecycleBin/DeletedDocTypes';
import DeletedDatasets from '@/components/extract/views/RecycleBin/DeletedDatasets';
import RecycleBinIcon from '@/components/common/elements/Navigation/RecycleBinIcon';
import { ConfigAPI } from '@/API/extract/ConfigAPI';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';

export default {
  name: 'RecycleBinView',

  components: {
    PageTabs,
    DeletedDocTypes,
    DeletedDatasets,
    RecycleBinIcon,
  },

  data() {
    return ({
      activeTab: 'doctypes',
      docTypesTotal: 0,
      datasetsTotal: 0,
      config: null,
    })
  },

  computed: {
    tabItems() {
      const tabs = [];
      if (this.docTypesTotal > 0) {
        tabs.push(
          {
            title: this.$t('docTypes.doc_types'),
            name: 'doctypes',
            bigNumber: this.docTypesTotal,
          }
        );
      }
      if (this.datasetsTotal > 0) {
        tabs.push(
          {
            title: this.$t('models.datasets'),
            name: 'datasets',
            bigNumber: this.datasetsTotal,
          }
        );
      }
      return tabs;
    },
  },

  watch: {
    docTypesTotal() {
      this.getActiveTab();
    },

    datasetsTotal() {
      this.getActiveTab();
    },
  },

  async created() {
    await this.getConfig();
    await this.getDeletedDoctypesTotal();
    await this.getDeletedDatasetsTotal();
    this.getActiveTab();
  },

  methods: {
    async getConfig() {
      this.config = await ConfigAPI.get();
    },

    getActiveTab() {
      if (this.docTypesTotal === 0 && this.datasetsTotal > 0) {
        this.activeTab = 'datasets';
      }
      if (this.datasetsTotal === 0 && this.docTypesTotal > 0) {
        this.activeTab = 'doctypes';
      }
      if (this.docTypesTotal === 0 && this.datasetsTotal === 0) {
        this.activeTab = '';
      }
    },

    async getDeletedDatasetsTotal() {
      try {
        const response = await DatasetAPI.get(0, 1, '', 'deleted');
        this.datasetsTotal = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        console.log(error);
      }
    },

    async getDeletedDoctypesTotal() {
      try {
        const response = await DocTypeAPI.get(1, 0, null, 'deleted');
        this.docTypesTotal = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recycle-bin-view {
  position: relative;
  height: 100%;
}

.empty-bin-card {
  width: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
}

</style>
