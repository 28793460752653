import { i18n } from '@/plugins/i18n/i18n';

const locale = i18n.global;

export const STATE_OPTIONS = [
  {
    name: locale.t('workflows.steps.done'),
    value: {
      action: 'done',
      action_type: 'state',
    },
    macroType: 'state',
  },
  {
    name: locale.t('workflows.steps.error'),
    value: {
      action: 'error',
      action_type: 'state',
    },
    macroType: 'state',
  },
  {
    name: locale.t('workflows.steps.custom'),
    value: {
      action: 'custom-state',
      action_type: 'state',
    },
    macroType: 'state',
  },
];

export const EXTRA_ACTION_OPTIONS = [
  {
    name: locale.t('workflows.steps.transfert-email'),
    value: {
      action: 'transfer-email',
      action_type: 'bindings',
    },
    macroType: 'action',
    taskType: 'automation',
    supports: ['email'],
    future: true,
  },
  {
    name: locale.t('workflows.steps.send-email'),
    value: {
      action: 'send-email',
      action_type: 'bindings',
    },
    macroType: 'action',
    taskType: 'automation',
    supports: ['email'],
    future: true,
  },
  {
    name: locale.t('workflows.steps.openstreetmap'),
    value: {
      action: '# OpenStreetMap code',
      action_type: 'python',
      action_name: 'openstreetmap',
    },
    macroType: 'code',
    taskType: 'validation',
  },
  {
    name: locale.t('workflows.steps.openai'),
    value: {
      action: '# OpenAI code',
      action_type: 'python',
      action_name: 'openai',
    },
    macroType: 'ai_agent',
    taskType: 'generation',
    future: true,
  },
];

export function createStepOption(name, action, value, owner = null) {
  return {
    name,
    value,
    owner,
    config: action.config,
    macroType: action.macro_type,
    taskType: action.task_type,
    supports: action.supports,
  }
}
