<template><div /></template>

<script>
import jwtDecode from "jwt-decode";
import { http } from '@/plugins/axios';
import { AuthAPI } from '@/API/authenticator/AuthAPI';

export default {
  name: 'LogoutView',

  async mounted() {
    const accessToken = localStorage.getItem('token');

    if (accessToken) {
      const user_id = jwtDecode(accessToken).user_id;
      try {
        await http.post(
          `system_2/verify/stop_all_verifications/${user_id}/`,
          {},
          {globalErrorHandlingOptOut: true},
        );
      } catch (err) {
        console.log(err);
      }
      AuthAPI.revoke({ access_token: accessToken });
    }
    this.$store.commit('logout');
    localStorage.clear();
    sessionStorage.clear();
    // We catch useless error about login page redirect
    this.$router.push({ path: "/" }).catch(() => {});
  },
};
</script>
